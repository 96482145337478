import React, { useState } from "react"
import ProductDataBlock from "./ProductDataBlock"
import ProductOverviewBlock from "./ProductOverviewBlock"

import { useTranslation } from "react-i18next"

const ProductTabsBlock = ({ entry, variants, techData, commercialData }) => {
  const { t } = useTranslation()
  const [activeTabState, setActiveTabState] = useState("overview")

  let applicationData = []
  if (entry?.properties?.mainApplication?.length > 0) {
    applicationData.push({
      translated_label: `${t("generic.tabs.main_application")}`,
      data: entry?.properties?.mainApplication,
    })
  }
  if (entry?.properties?.sideApplication?.length > 0) {
    applicationData.push({
      translated_label: `${t("generic.tabs.other_application")}`,
      data: entry?.properties?.sideApplication,
    })
  }

  const handleTabClick = (tab) => {
    setActiveTabState(tab)
    return false
  }

  return (
    <section className="product-tabs">
      <div className="bg-black">
        <div className="mx-auto max-w-screen-xl">
          <div className="flex flex-row overflow-y-auto px-grid">
            <button
              className={`btn whitespace-nowrap ${
                activeTabState === "overview"
                  ? "bg-white pointer-events-none"
                  : " bg-gray-100 hover:bg-gray-200"
              }`}
              onClick={(e) => handleTabClick("overview")}
            >
              {t("generic.tabs.overview")}
            </button>
            {techData?.length > 0 && (
              <button
                className={`btn whitespace-nowrap ${
                  activeTabState === "data"
                    ? "bg-white pointer-events-none"
                    : " bg-gray-100 hover:bg-gray-200"
                }`}
                onClick={(e) => handleTabClick("data")}
              >
                {t("generic.tabs.technical_data")}
              </button>
            )}
            {applicationData?.length > 0 && (
              <button
                className={`btn whitespace-nowrap ${
                  activeTabState === "application"
                    ? "bg-white pointer-events-none"
                    : " bg-gray-100 hover:bg-gray-200"
                }`}
                onClick={(e) => handleTabClick("application")}
              >
                {t("generic.tabs.application_areas")}
              </button>
            )}
            {commercialData?.length > 0 && (
              <button
                className={`btn whitespace-nowrap ${
                  activeTabState === "commercial"
                    ? "bg-white pointer-events-none"
                    : " bg-gray-100 hover:bg-gray-200"
                }`}
                onClick={(e) => handleTabClick("commercial")}
              >
                {t("generic.tabs.business_data")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="w-full mx-auto">
        <div
          className={` ${activeTabState === "overview" ? "block" : "hidden"}`}
        >
          <ProductOverviewBlock
            entry={entry}
            variants={variants}
            language={entry.language}
          />
        </div>
        <div className={` ${activeTabState === "data" ? "block" : "hidden"}`}>
          <ProductDataBlock
            block={{
              dataTitle: `${t("generic.tabs.technical_data")}`,
              dataContent: techData,
            }}
            language={entry.language}
          />
        </div>
        <div
          className={` ${
            activeTabState === "application" ? "block" : "hidden"
          }`}
        >
          <ProductDataBlock
            block={{
              dataTitle: `${t("generic.tabs.application_areas")}`,
              dataContent: applicationData,
            }}
            language={entry.language}
          />
        </div>
        <div
          className={` ${activeTabState === "commercial" ? "block" : "hidden"}`}
        >
          <ProductDataBlock
            block={{
              dataTitle: `${t("generic.tabs.business_data")}`,
              dataContent: commercialData,
            }}
            language={entry.language}
          />
        </div>
      </div>
    </section>
  )
}

export default ProductTabsBlock
