import React from "react"
import Slider from "react-slick"

import { CaretLeft16, CaretRight16 } from "@carbon/icons-react"

const SliderWrapper = ({ children, showSlides }) => {
  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretRight16 />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretLeft16 />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: showSlides,
    slidesToScroll: showSlides,
    dots: false,
    arrows: true,
    mobileFirst: true,
    //infinite: children.length > showSlides ? true : false,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,

    responsive: [
      // {
      //   breakpoint: 1312,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //   },
      // },
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 672,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="-mx-grid">
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

export default SliderWrapper
