import React from "react"

import Slider from "react-slick"

import { CaretLeft16, CaretRight16 } from "@carbon/icons-react"

const GridWrapper = ({ children }) => {
  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretRight16 />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretLeft16 />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    arrows: true,
    mobileFirst: true,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,

    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 1055,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 671,
        settings: {
          infinite: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Slider className="flex flex-row flex-wrap md:-mt-4 -mx-grid" {...settings}>
      {children}
    </Slider>
  )
}

export default GridWrapper
