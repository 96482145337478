import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../../src/components/modules/Layout"

import ProductHeader from "../../../src/components/blocks/ProductHeader"
import ProductTabsBlock from "../../../src/components/blocks/ProductTabsBlock"

import Seo from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Seo"

import CrossSellingVariantsCardsBlock from "../../../src/components/blocks/CrossSellingVariantsCardsBlock"

import { useTranslation } from "react-i18next"
import striptags from "striptags"

const VariantsTemplate = ({ pageContext, data }) => {
  const { area, group, parentProduct, crossSellingProducts } = data
  let entry = { ...data.entry, parentProduct }
  const {
    crossSellingVariantIDs: crossSellingVariantIDsOrder,
    crossSellingProductIDs,
  } = pageContext
  const commercialData = JSON.parse(entry.properties.commercialContent)
  const techData = JSON.parse(entry.properties.techContent)
  const variants = data.variants.edges
  const crossSellingVariants = data.crossSellingVariants.edges
    .sort(function (a, b) {
      return (
        crossSellingVariantIDsOrder.indexOf(a.node.ID) -
        crossSellingVariantIDsOrder.indexOf(b.node.ID)
      )
    })
    .map((variant) => {
      let p =
        crossSellingProducts.edges.find(
          ({ node }) => node.ID === variant.node.parentId
        )?.node || null
      return { node: { ...variant.node, parentProduct: p } }
    })

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
    {
      link: area?.fullSlug,
      link_text: area?.name,
    },
    {
      link: group?.fullSlug,
      link_text: group?.name,
    },
  ]

  return (
    <Layout language={entry.language}>
      <Seo
        title={`${entry.name} ${entry.externalKey} | ${group.name}`}
        description={`${striptags(
          entry.properties?.longDescription?.value?.Formatted?.replace(
            /<\/li><li>/g,
            "</li> | <li>"
          )
        )}`}
        og_image={entry.properties?.mainImage}
        language={entry.language}
      />
      <ProductHeader
        block={{ headline: entry.name, _uid: entry.ID }}
        breadcrumbs={breadcrumbs}
      />
      <ProductTabsBlock
        entry={entry}
        variants={variants}
        techData={techData}
        commercialData={commercialData}
      />
      {crossSellingVariants.length > 0 && (
        <CrossSellingVariantsCardsBlock
          block={{
            cross_selling_variant_items: crossSellingVariants,
          }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query VariantQuery(
    $id: String
    $parentId: String!
    $locale: String
    $groupId: String
    $areaId: String
    $crossSellingVariantIDs: [String]
    $crossSellingProductIDs: [String]
  ) {
    entry: contentServVariant(ID: { eq: $id }, locale: { eq: $locale }) {
      name
      externalKey
      ID
      language
      fullSlug
      legacyFullSlug
      rootPage {
        name
        fullSlug
      }
      properties {
        description
        mainImage
        images
        productVideoFilenames
        variantChangeOver
        commercialContent
        dimensionsContent
        techContent
        pictograms
        mainApplication
        sideApplication
        longDescription {
          label
          value {
            Formatted
          }
        }
      }
    }
    parentProduct: contentServProduct(
      ID: { eq: $parentId }
      locale: { eq: $locale }
    ) {
      properties {
        isNewFlag
        isNewStart
        isNewEnd
      }
    }
    group: contentServProductGroup(
      groupId: { eq: $groupId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    area: contentServProductArea(
      areaId: { eq: $areaId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    variants: allContentServVariant(
      filter: { parentId: { eq: $parentId }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          fullSlug
          properties {
            variantChangeOver
          }
        }
      }
    }
    crossSellingVariants: allContentServVariant(
      filter: { ID: { in: $crossSellingVariantIDs }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          ID
          name
          fullSlug
          parentId
          properties {
            mainImage
          }
        }
      }
    }
    crossSellingProducts: allContentServProduct(
      filter: { ID: { in: $crossSellingProductIDs }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          ID
          properties {
            isNewFlag
            isNewStart
            isNewEnd
          }
        }
      }
    }
  }
`

export default VariantsTemplate
