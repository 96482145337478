import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import GridWrapper from "../technical/GridWrapper"
import SliderWrapper from "../technical/SliderWrapper"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { ArrowRight16 } from "@carbon/icons-react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import { useTranslation } from "react-i18next"
import NewFlag from "../atoms/NewFlag"
import useDateCheck from "../../hooks/useDateCheck"

const CrossSellingVariantsCardsBlock = ({ block }) => {
  const { t } = useTranslation()
  let view = block.view?.length > 0 ? block.view : "slider"
  let showSlides =
    block.show_slides && block.show_slides !== "" ? block.show_slides : 6

  if (view === "grid") {
    return (
      <BlockWrapper block={block} blockWidth="container" showHeadline="false">
        <div
          id="variants"
          className="flex flex-row items-baseline justify-between pb-10"
        >
          <Headline
            headline={t("generic.tabs.cross_selling_title")}
            headlineLevel="h4"
            tagName="h2"
          />
          {block.link_text && (
            <Link className="ml-4" link={block.link}>
              {block.link_text}
              <ArrowRight16 className="inline-block ml-2 -mt-0.5 align-middle" />
            </Link>
          )}
        </div>
        <GridWrapper showSlides={showSlides}>
          {block.cross_selling_variant_items?.length > 0 &&
            block.cross_selling_variant_items.map(
              (item, index) =>
                item?.node?.fullSlug?.length > 0 &&
                item?.node?.name?.length > 0 && (
                  <div
                    className="w-full px-grid lg:w-1/4 focus:outline-none"
                    key={index}
                  >
                    <Link
                      className="flex flex-col w-full group"
                      link={item.node.fullSlug}
                    >
                      <div className="block w-full border border-gray-100 aspect-square relative overflow-hidden">
                        {useDateCheck(
                          item.node.parentProduct.properties.isNewStart,
                          item.node.parentProduct.properties.isNewEnd,
                          item.node.parentProduct.properties.isNewFlag
                        ) && <NewFlag type="gridCard" />}
                        <Image
                          className="w-full h-auto min-h-full"
                          image={item.node.properties.mainImage}
                          aspectRatio="1by1"
                          fitIn="true"
                          fillColor="ffffff"
                        />
                      </div>
                      <div className="flex-1 p-4 -mt-px border border-gray-100">
                        <div>
                          <span className="font-bold group-hover:text-primary hyphens-auto">
                            {item.node.name}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
            )}
        </GridWrapper>
      </BlockWrapper>
    )
  } else {
    return (
      <BlockWrapper block={block} blockWidth="container" showHeadline="false">
        <div
          id="variants"
          className="flex flex-row items-baseline justify-between pb-10"
        >
          <Headline
            headline={t("generic.tabs.cross_selling_title")}
            headlineLevel="h4"
            tagName="h2"
          />
          {block.link_text && (
            <Link className="ml-4" link={block.link}>
              {block.link_text}
              <ArrowRight16 className="inline-block ml-2 -mt-0.5 align-middle" />
            </Link>
          )}
        </div>
        <SliderWrapper showSlides={showSlides}>
          {block.cross_selling_variant_items?.length > 0 &&
            block.cross_selling_variant_items.map(
              (item, index) =>
                item?.node?.fullSlug?.length > 0 &&
                item?.node?.name?.length > 0 && (
                  <div className="w-full h-full px-grid lg:w-1/4" key={index}>
                    <Link
                      className="flex flex-col w-full h-full group"
                      link={item.node.fullSlug}
                    >
                      <div className="block w-full border border-gray-100 aspect-square relative overflow-hidden">
                        {useDateCheck(
                          item.node.parentProduct.properties.isNewStart,
                          item.node.parentProduct.properties.isNewEnd,
                          item.node.parentProduct.properties.isNewFlag
                        ) && <NewFlag type="smallCard" />}
                        <Image
                          className="w-full h-auto min-h-full"
                          image={item?.node?.properties?.mainImage}
                          aspectRatio="1by1"
                          fitIn="true"
                          fillColor="ffffff"
                        />
                      </div>
                      <div className="flex-1 p-4 -mt-px border border-gray-100">
                        <div className="min-h-[3rem]">
                          <span className="font-bold group-hover:text-primary hyphens-auto">
                            {item.node.name}
                          </span>
                          {/* <p className="mt-2 prose-sm ">{item.text}</p> */}
                        </div>
                      </div>
                    </Link>
                  </div>
                )
            )}
        </SliderWrapper>
      </BlockWrapper>
    )
  }
}

export default CrossSellingVariantsCardsBlock
